import React from "react";

const Footer = () => {
  return (
    <div
      className="footer text-left"
    >
      <div className="container">&copy; 2023 The Dad Weather Project</div>
    </div>
  );
};

export default Footer;
