import React, { useState } from "react";
import "./App.css";
import { Input, Form, Button, FormGroup, Label } from "reactstrap";
import Navigation from "./components/header.js";
import logo from "./dad.svg";
import Footer from "./components/footer.js";

const api = {
  key: process.env.REACT_APP_OPENWEATHER_API_KEY,
  base: "https://api.openweathermap.org/data/2.5/",
};

function App() {
  const [location, setLocation] = useState(null);
  const [query, setQuery] = useState("");
  const [weather, setWeather] = useState({});

  const search = (evt) => {
    if (evt.key === "Enter") {
      fetch(`${api.base}weather?q=${query}&units=imperial&APPID=${api.key}`)
        .then((res) => res.json())
        .then((result) => {
          setWeather(result);
          setQuery("");
          console.log(result);
        });
    }
  };

  return (
    <div className="App">
      <Navigation />
      <div className="App-header mb-5">
        <div className="container">
          <div className="logo">
            <img
              src={logo}
              className="mb-4"
              alt="dad logo"
              style={{ maxWidth: "200px" }}
            ></img>
            {typeof weather.main === "undefined" ? (
              <div>
                <h1>Dad Weather</h1>
                <h2 className="mt-4 mb-5">
                  A simple weather app, with dad jokes.
                </h2>
                <FormGroup>
                  <Input
                    className="mb-4"
                    label="Search by City"
                    name="Search by City"
                    style={{ maxWidth: "300px", margin: "auto" }}
                    type="text"
                    placeholder="Search By City"
                    onChange={(e) => setQuery(e.target.value)}
                    value={query}
                    onKeyDown={search}
                  />
                </FormGroup>
              </div>
            ) : (
              <div />
            )}
          </div>

          {typeof weather.main != "undefined" ? (
            <div className="weather-result mt-4 mb-4">
              {random_item(jokes)}

              <div className="location mt-4">
                {weather.name}, {weather.sys.country}
              </div>
              <div className="temp">{Math.round(weather.main.temp)}°F</div>
              <img
                src={`http://openweathermap.org/img/wn/${weather.weather[0].icon}.png`}
              ></img>
              <div className="weather pb-1">{weather.weather[0].main}</div>
              <div className="description">
                Feels like {Math.round(weather.main.feels_like)}°F with{" "}
                {weather.weather[0].description}, {weather.main.humidity}%
                humidity and {Math.round(weather.wind.speed)} mph winds.
              </div>
              <Button className="primary" onClick={refreshPage}>
                Search Again
              </Button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

function refreshPage() {
  window.location.reload(false);
}

function random_item(jokes) {
  return jokes[Math.floor(Math.random() * jokes.length)];
}

var jokes = [
  <div>
    Did you hear about the woman who wore sunglasses? <br />
    She had a very dim view of things.
  </div>,
  <div>
    What falls but never hits the ground? <br />
    The temperature.
  </div>,
  <div>
    What is a queen's favorite kind of precipitation? <br />
    Reign!
  </div>,
  <div>
    Why did the man use ketchup in the rain?
    <br />
    Because it was raining cats and hot dogs
  </div>,
  <div>
    Where do lightning bolts go on dates? <br />
    To cloud nine.
  </div>,
  <div>
    What did the evaporating raindrop say? <br />
    I'm going to pieces.
  </div>,
  <div>
    Why does Snoop Dogg need an umbrella? <br />
    Fo’ drizzle.
  </div>,
  <div>
    What does it do before it rains candy? <br />
    It sprinkles!
  </div>,
  <div>
    What do you call it when it’s pouring ducks and geese? <br />
    Fowl weather!
  </div>,
  <div>
    What do you call a bear that got caught in a storm? <br />A drizzly bear.
  </div>,
  <div>
    What does a weatherman wear under his trousers? <br />
    Thunderpants.
  </div>,
  <div>
    What do you call a snowman that tells tall tales? <br />A snow-fake!
  </div>,
  <div>
    What do snowmen eat for lunch? <br />
    Icebergers
  </div>,
  <div>
    What happens when the fog lifts in California? <br />
    UCLA!
  </div>,
  <div>
    What does everyone listen to, but no one believes? <br />
    The weather reporter.
  </div>,
  <div>
    What is the opposite of a cold front? <br />A warm back
  </div>,
  <div>
    How do hurricanes see? <br />
    With one eye
  </div>,
  <div>
    What did the cloud say to the lightning bolt? <br />
    You're shocking!
  </div>,
  <div>
    {" "}
    What's a tornado's favorite game? <br />
    Twister!
  </div>,
  <div>
    Why did the woman go outdoors with her purse open?
    <br />
    Because she expected some change in the weather.
  </div>,
  <div>
    Why do bananas have to put on sunscreen before they go to the beach? <br />
    They peel!
  </div>,
  <div>
    Why is the sun so smart? <br />
    It has over 5,000 degrees
  </div>,
  <div>
    What do you call two straight days of rain in Seattle? <br />A weekend.
  </div>,
  <div>
    What type of cloud is so lazy, because it will never get up? <br />
    Fog!
  </div>,
  <div>
    How did my cat know about tomorrow’s weather? <br />
    He looked at the fur-cast.
  </div>,
  <div>
    What bow can’t be tied? <br />A rainbow!
  </div>,
  <div>
    Why did the weather want privacy? <br />
    It was changing.
  </div>,
  <div>
    What type of sense of humor does a dust storm have? <br />A very dry sense
    of humor.
  </div>,
  <div>
    What do you get from sitting on the snow too long?
    <br />
    Polaroids!
  </div>,
  <div>
    What did one raindrop say to the other?
    <br />
    Two's company, three's a cloud.
  </div>,
  <div>
    Why do mother kangaroos hate rainy days?
    <br />
    Because then the children have to play inside.
  </div>,
  <div>
    What is the best day to go to the beach?
    <br />
    Sun-day, of course.
  </div>,
  <div>
    What is a king's favorite kind of precipitation?
    <br />
    Hail!
  </div>,
  <div>
    What is a Mexican weather report?
    <br />
    Chilli today, hot tamale.
  </div>,
  <div>
    Why do raindrops like lightning at night?
    <br />
    So they can see where they are going.
  </div>,
  <div>
    What goes up when the rain comes down?
    <br />
    An Umbrella.
  </div>,
  <div>
    What did the thermometer say to the other thermometer?
    <br />
    You make my temperature rise.
  </div>,
  <div>
    Why do hurricanes travel so fast?
    <br />
    Because if they travelled slowly, we’d have to call them slow-i-canes.
  </div>,
  <div>
    Where’s the best place to store your rain?
    <br />
    In a cloud bank.
  </div>,
  <div>
    What did the tornado say to the sports car?
    <br />
    Want to go for a spin!
  </div>,
  <div>
    What happens when it rains cats and dogs?
    <br />
    You might step in a poodle!
  </div>,
  <div>
    Why shouldn’t you fight with a cloud?
    <br />
    He’ll storm out on you!
  </div>,
  <div>
    What do you call a wet bear?
    <br />A drizzly bear.
  </div>,
  <div>
    Why did the lightning get into trouble?
    <br />
    It didn’t know how to conduct itself.
  </div>,
  <div>
    Why should you avoid tornado chasers?
    <br />
    They’re always passing wind.
  </div>,
  <div>
    What does daylight saving time mean in Seattle?
    <br />
    An extra hour of rain.
  </div>,
  <div>
    Where do snowmen keep their money?
    <br />
    In a snow bank.
  </div>,
];
console.log(random_item(jokes));

export default App;
